var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.forDialog
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    !_vm.iconShaped
                      ? _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mt-2",
                              attrs: {
                                color: "primary",
                                loading: _vm.loading,
                                "e2e-table-query-submit": "",
                              },
                              on: { click: _vm.submitQuery },
                            },
                            on
                          ),
                          [
                            _vm.mode ===
                            _vm.queryModeOptions.FULL_WITH_SAVE_MODE
                              ? _c(
                                  "v-icon",
                                  { attrs: { left: "", small: "" } },
                                  [_vm._v("send_and_archive")]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { left: "", small: "" } },
                                  [_vm._v("send")]
                                ),
                            _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                          ],
                          1
                        )
                      : _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "font-weight-bold mr-1",
                              attrs: {
                                icon: "",
                                loading: _vm.loading,
                                color: "secondary",
                                "e2e-table-query-submit": "",
                              },
                              on: { click: _vm.submitQuery },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        ),
                  ]
                },
              },
            ],
            null,
            false,
            467278925
          ),
        },
        [_c("span", [_vm._v(_vm._s(_vm.tooltipHint))])]
      )
    : _c(
        "v-btn",
        {
          attrs: { color: "primary", "e2e-table-query-submit": "" },
          on: { click: _vm.submitQuery },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _vm.mode === _vm.queryModeOptions.FULL_WITH_SAVE_MODE
                ? _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("send_and_archive"),
                  ])
                : _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("send"),
                  ]),
              _vm._v(" " + _vm._s(_vm.buttonName) + " "),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }